<template>
  <main class="page-zone pageMain bg-hex-EFF6FE" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <div v-swiper:zoneSwiper="swiperOption">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(banner, index) in banners" :key="'banner' + index">
          <div>
            <img :src="banner.image" class="block w-full h-500 object-cover" :alt="banner.title">
          </div>
        </div>
      </div>
      <!-- <div class="swiper-pagination swiper-pagination-bullets" :class="banners.length > 1 ? '' : 'hidden'"></div> -->
    </div>

    <div class="container mt-64 pb-160">
      <div class="h-56 bg-white rounded-40 flex-y-center">
        <div class="i-ion-ios-search size-20 ml-18 text-hex-BBBDBF"></div>
        <input
          type="text"
          class="w-full ml-10 border-none text-16 text-hex-5D5D5B mr-30"
          :placeholder="globalDataArray.siteTemplateLanguage.zone_search_placeholder"
          v-model="search"
          @keyup.enter="getZoneList"
        >
      </div>

      <ul class="flex gap-40 flex-wrap mt-40">
        <li v-for="item in zoneListing" :key="item.id" class="w-372 h-469 bg-white rounded-16 border-1 border-color-[rgba(207,219,213,0.6)]">
          <router-link class="block" :to="'/competition?zone_id=' + item.parentId">
            <div class="relative">
              <img :src="item.image" :alt="item.title" class="block w-full h-302 object-cover rounded-t-16">
              <div class="absolute top-12 left-12 bg-white rounded-23 border-1 border-color-[rgba(207,219,213,0.6)] text-14 leading-22 font-600 h-46 flex-y-center px-16">
                <div class="i-ion-android-time size-18"></div>
                <div class="ml-8">{{ item.startTime.replace(/-/g, '.') }}-{{ item.endTime.replace(/-/g, '.') }}</div>
              </div>
            </div>
            <div class="px-16 pt-16">
              <h2 class="text-18 font-600 leading-24 text-hex-333 truncate">{{ item.title }}</h2>
              <div class="flex-y-center justify-between mt-28">
                <div class="text-12 leading-18 text-hex-687489 font-medium">{{ globalDataArray.siteTemplateLanguage.zone_competition_count }}</div>
                <div class="flex-y-center">
                  <div class="i-icons:layer size-16"></div>
                  <div class="text-16 font-600 leading-24 ml-4 text-hex-333">{{ item.competitionCount }}</div>
                </div>
              </div>

              <hr class="h-1 bg-[rgba(207,219,213,0.6)] mt-16 border-none">

              <div class="flex-y-center justify-between mt-16">
                <div class="text-12 leading-18 text-hex-687489 font-medium">{{ globalDataArray.siteTemplateLanguage.zone_sponsor }}</div>
                <div class="flex-y-center">
                  <div class="i-icons:building size-16"></div>
                  <div class="text-14 font-400 leading-22 ml-4 text-hex-333">{{ item.sponsor }}</div>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import { getZoneList } from '@/api/zone'
import { getAdList } from '@/api/api'

export default {
  name: 'Zone',

  data() {
    return {
      title: this.globalDataArray.siteNavigation[1].title + '-' + this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteNavigation[1].keywords,
      keywords: this.globalDataArray.siteNavigation[1].description,

      banners: [],
      zoneListing: [],
      search: '',

      swiperOption: {
        autoplay: 5000,
        initialSlide: 0,
        // loop: true,
        pagination: '.swiper-pagination'
      }
    }
  },

  mounted() {
    this.getAdList()
    this.getZoneList()
  },

  methods: {
    async getAdList() {
      const { success, result } = await getAdList('alias=zonelist_banner')

      if (success) {
        this.banners = result
      }
    },

    async getZoneList() {
      const { success, result } = await getZoneList(this.search)

      if (success) {
        this.zoneListing = result
      }
    }
  },

  metaInfo() {
    let title = ''
    let description = ''
    let keywords = ''

    title = this.globalDataArray.siteTemplateLanguage.zone_page_title + '-' + this.globalDataArray.siteInfo.title
    description = this.globalDataArray.siteInfo.description
    keywords = this.globalDataArray.siteInfo.keywords

    return {
      title: title,
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'keywords', name: 'keywords', content: keywords }
      ]
    }
  }
}
</script>

<style lnag="scss" scoped>
</style>
