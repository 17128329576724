import { axios } from '@/utils/http'

export const getZoneList = (search) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/zone?s=' + encodeURIComponent(search))

export const getZoneDetail = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/zone/detail?' + params)
